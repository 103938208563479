var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "postUserNum",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            color: "#409EFF",
                            "text-style": "none"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickNumber(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.postUserNum) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c("importBtn", {
                    attrs: {
                      outApi: this.proApis.POSTEXPORT,
                      innerAPI: this.proApis.POSTIMPORT,
                      API: this.proApis.POSTIMPORTTEMPLATE
                    },
                    on: { refresh: _vm.refreshData }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("JOB CLASSIFICATION"),
                        size: "small"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    _vm._l(_vm.listType, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("BELONGING WORKING PROCEDURE"),
                        size: "small"
                      },
                      model: {
                        value: _vm.processId,
                        callback: function($$v) {
                          _vm.processId = $$v
                        },
                        expression: "processId"
                      }
                    },
                    _vm._l(_vm.listWkln, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("岗位名称"),
                      size: "small"
                    },
                    model: {
                      value: _vm.nameOrCode,
                      callback: function($$v) {
                        _vm.nameOrCode = $$v
                      },
                      expression: "nameOrCode"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }